<template>
  <div>
    <v-container>
      <page-title title="Vendor">
        <template slot="action">
        </template>
      </page-title>
      <v-row>
        <v-col>
          <v-card elevation="3">
            <v-tabs v-model="tab">
              <v-tab>New Vendor</v-tab>
              <v-tab>All</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <ListDataNew></ListDataNew>
              </v-tab-item>
              <v-tab-item>
                <ListData></ListData>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="9">
        </v-col>
        <v-col md="3">

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ListData from "./components/ListData.vue";
import ListDataNew from "./components/ListDataNew.vue";
export default {
  components: {
    ListData,
    ListDataNew,
  },
  data() {
    return {
      tab: null,
    };
  },

  methods: {
  },

  mounted() {
    this.requiredLogin();

    this.modulePermission("vendor", "view", true);
  },
};
</script>
